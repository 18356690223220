import React from 'react'
import { isMobile } from 'react-device-detect'
import Nav from './Header/Nav';
import styled from 'styled-components'

const SideNavigation = styled.div`
  width: 100%;  
`


function SideNavbar() {
  return (
    <SideNavigation style={{paddingTop: '40px'}}>
          {!isMobile && <Nav />}        
    </SideNavigation>
  )
}

export default SideNavbar
