import React from 'react'
import styled from 'styled-components'
import TranslatedText from '../TranslatedText'
// import { GiNotebook } from 'react-icons/gi';

const iconexchange = require('../../assets/images/entry-icon-swap.svg');
// const iconfarming = require('../../assets/images/entry-icon-farms.svg');
// const iconstake = require('../../assets/images/entry-icon-staking.svg');
const iconpool =require('../../assets/images/icon-pools.svg');
const iconliquidity =require('../../assets/images/icon-liquidity.svg');

const Nav: React.FC = () => {
  return (
    <StyledNav>
      {/* <StyledAbsoluteLink href="/" className="active">
      <img src={iconexchange} style={{height:"20px", width:"30px" ,position: 'relative', top: '4px'}} />
        <TranslatedText translationId={202}>Exchange</TranslatedText>
      </StyledAbsoluteLink> */}
      <StyledAbsoluteLink href="#swap">
         <img src={iconexchange} style={{height:"20px", width:"30px", position: 'relative', top: '4px'}} />
        <TranslatedText translationId={198}>Swap</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="#/add/ETH">
        <img src={iconliquidity} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} />
        <TranslatedText translationId={200}>Liquidity</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="#pool">
        <img src={iconpool} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} />
        <TranslatedText translationId={200}>Pools</TranslatedText>
      </StyledAbsoluteLink>
      
      {/* <StyledAbsoluteLink href="#">
        <img src={iconfarming} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} />
        <TranslatedText translationId={200}>LP Farm</TranslatedText>
      </StyledAbsoluteLink>

      <StyledAbsoluteLink href="#">
        <img src={iconstake} style={{height:"20px", width:"30px",position: 'relative', top: '4px'}} />
        <TranslatedText translationId={200}>Staking</TranslatedText>
      </StyledAbsoluteLink> */}
{/* 
      <StyledAbsoluteLink href="#voting">
      
        <GiVote style={{height:"20px", width:"30px",position: 'relative', top: '4px', color:"#2c99ac"}} />
        <TranslatedText translationId={200}>Voting</TranslatedText>
      </StyledAbsoluteLink> */}

      {/* <StyledAbsoluteLink href="#proposal">
        
        <GiNotebook style={{height:"20px", width:"30px",position: 'relative', top: '4px',color:"#2c99ac"}}/>
        <TranslatedText translationId={200}>Proposal</TranslatedText>
      </StyledAbsoluteLink> */}

      {/* <StyledAbsoluteLink href="https://puddingswap.finance/pudvault">
        <TranslatedText translationId="nav-pud-vault">LockVault</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="https://puddingswap.finance/ePud">
        <TranslatedText translationId={218}>ePUD Pools</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="https://puddingswap.finance/chefnft">NFT</StyledAbsoluteLink> */}
     
      {/* <StyledAbsoluteLink href="https://puddingswap.finance/ido" className="active">
        IDO
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="https://info.puddingswap.finance">
        <TranslatedText translationId={348}>Analytics</TranslatedText>
      </StyledAbsoluteLink>
      <StyledAbsoluteLink href="https://voting.puddingswap.finance">
        <TranslatedText translationId={370}>Voting</TranslatedText>
      </StyledAbsoluteLink> */}
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
  flex-direction:column;
  font-size: 14px;
  font-weight: 700;
`

const StyledAbsoluteLink = styled.a`
  position: relative;
  color: #fff;
  // margin-right: 24px;
  text-decoration: none;
  &:hover {
    //color: ${({ theme }) => theme.colors.primary};
    background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
    color:#fff;
  }
  @media (max-width: 400px) {
    margin-right: 24px;
  }
`
export default Nav
