export const contractAddresses = {

  weth: {
    1206: '0x0aa0C9E19117483d0Aa3bd4Ef3E4Bf5AaC121BD8',
    1423: '0x22522f9c0da09a80b8effb4e0e325904c71803f3',
  }
}

const multicall = {
  1206: '0x237Cd3f0241979552C52d9C61cE3c9d7a2B72eA7', //multicall address
  1423: '0xbFFeD067EFC03F8a85aEE2076FC844BfEa356886',
}
const chainId = 1423

interface AddressProps {
  1423: string // Address of the contract
}
export const getAddress = (obj: AddressProps) => {
  return obj[chainId] ? obj[chainId] : obj
}

export const getMulticallAddress = () => {
  return multicall[chainId]
}

export const getPipiAddress = () => {
  return getAddress(contractAddresses.weth)
}
