import React, { useContext, useMemo } from 'react'
// import React, { useRef, useState } from 'react'

import styled, { ThemeContext } from 'styled-components'
import { Pair } from 'calculas-sdk'
import { Link } from 'react-router-dom'
// import { SwapPoolTabs } from '../../components/NavigationTabs'

import Question from '../../components/QuestionHelper'
import FullPositionCard from '../../components/PositionCard'
import { useUserHasLiquidityInAllTokens } from '../../data/V1'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../components/Shared'
import { Text } from 'rebass'
import { LightCard } from '../../components/Card'
import { RowBetween } from '../../components/Row'
// import { ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
// import { BodyWrapper } from '../AppBody'
import { Dots } from '../../components/swap/styleds'
import TranslatedText from '../../components/TranslatedText'
import { useI18n } from 'i18n/i18n-react'
// import CandleGraph from '../../mypages/CandleGraph'
// import styled from 'styled-components'
import { DisplayFlexBT, Top2Card, Topcard } from '../LP_Farm/styleds'
import '../../components/Accordion/Accordion.css';
// import { CircularProgressbar } from 'react-circular-progressbar';
// import { AiOutlineQuestionCircle } from 'react-icons/ai';
import SideNavbar from 'components/SideNavbar'
import Header from 'components/Header'
// import AddLiquidity from 'pages/AddLiquidity'

export function Accordion_pool() {

  const i18n = useI18n()
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user 's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  // const tokenSet = useMemo(() => trackedTokenPairs.map(item=> { console.log(toV2LiquidityToken(item), item,"item") }),
  // [trackedTokenPairs]
  // )
  // console.log(tokenSet,"tokenSet")
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const hasV1Liquidity = useUserHasLiquidityInAllTokens()
//   console.log(trackedTokenPairs,"trackedTokenPairs")
// console.log(tokenPairsWithLiquidityTokens,"tokenPairsWithLiquidityTokens")
// console.log(liquidityTokensWithBalances,"liquidityTokensWithBalances")
// console.log(v2PairsBalances,"fetchingV2PairBalances",fetchingV2PairBalances)
// console.log(allV2PairsWithLiquidity,"v2Pair",v2Pairs)
  return (
    <>

      {/* <SwapPoolTabs active={'pool'} /> */}
      <AutoColumn gap="lg" justify="center">
        {/* <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/add/ETH">
          <Text fontWeight={500} fontSize={20}>
            <TranslatedText translationId={288}>Add Liquidity</TranslatedText>
          </Text>
        </ButtonPrimary> */}


        <AutoColumn gap="12px" style={{ width: '100%' }}>
          <RowBetween padding={'0 8px'}>
            <Text color={theme.colors.text5} fontWeight={500}>
              <TranslatedText translationId={118}>Your Liquidity</TranslatedText>
            </Text>
            <Question
              text={i18n(
                302,
                'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
              )}
            />
          </RowBetween>

          {!account ? (
            <LightCard padding="40px">
              <TYPE.body color={theme.colors.text3} textAlign="center">
                {i18n(768, 'Connect to a wallet to view your liquidity.')}
              </TYPE.body>
            </LightCard>
          ) : v2IsLoading ? (
            <LightCard padding="40px">
              <TYPE.body color={theme.colors.text3} textAlign="center">
                <Dots>Loading</Dots>
              </TYPE.body>
            </LightCard>
          ) : allV2PairsWithLiquidity?.length > 0 ? (
            <>{
              allV2PairsWithLiquidity.map((v2Pair, i) => {
                return (
                  <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} pairData={i} />
                )
              })
            }
              {/* {allV2PairsWithLiquidity.map(v2Pair => (

                  <FullPositionCard  key={v2Pair.liquidityToken.address} pair={v2Pair}/>
                ))} */}
            </>
          ) : (
            <span>
              <Link className='w-100 mb-2' to="/add/ETH"><div className="w-100 mb-3 btn fw-bold frosted-glass-teal frosted-glass-teal clickable-filter-effect">Add Liquidity</div></Link>
              <LightCard padding="20px">
                <TYPE.body color={theme.colors.text3} textAlign="center">
                  <TranslatedText translationId={292}>No liquidity found.</TranslatedText>
                </TYPE.body>
              </LightCard>
            </span>

            
          )}

          <div>
            <Text textAlign="center" fontSize={14} style={{ padding: '.5rem 0 .5rem 0', color: '#fff' }}>
              {hasV1Liquidity ? 'Uniswap V1 liquidity found!' : i18n(304, "Don't see a pool you joined?")}{' '}
              <StyledInternalLink id="import-pool-link" to={hasV1Liquidity ? '/migrate/v1' : '/find'}>
                {hasV1Liquidity ? 'Migrate now.' : i18n(306, 'Import it.')}
              </StyledInternalLink>
            </Text>
          </div>
        </AutoColumn>
      </AutoColumn>
    </>
  )
}

export default function Pool() {
  // const [send, setSend] = useState("24H");

  const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  // width: 100%;
  justify-content: space-between;
`
const AppWrapper = styled.div`
//  display: flex;
//  flex-flow: column;
//   align-items: flex-start;
//  overflow-x: hidden;
//  min-height: 100vh;
`
  return (
    <>
     <AppWrapper id='mainwrapper'>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <div className='Main-div'>
        <div className='sidebar-wrapper'>
          <SideNavbar />
        </div>
        <div className='content-wrapper'>
          {/* <BodyWrapper id="bodywrapper"> */}
          <div className='container'>
            <h3 className='ms-2 text-white'>Pools</h3>
            <Topcard >
              <Top2Card className='mobile-scroll'>
                <DisplayFlexBT>
                  {/* <div >
                    <div className="text-lg text-white font-medium font-text">Liquidity Pools</div>
                    <div className="text-base text-color">Earn yield on trading fees by providing liquidity</div>
                  </div> */}
                  {/* <div className="d-flex align-items-center ms-3">
                    <div className="me-2">
                      <div className="dropdown">
                        <button className="btn btn-secondary1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Time Basis: {send}</button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li><a className="dropdown-item" onClick={() => { setSend("24H"); }}>24H</a></li>
                          <li><a className="dropdown-item" onClick={() => { setSend("7D"); }}>7D</a></li>
                          <li><a className="dropdown-item" onClick={() => { setSend("30D"); }}>30D</a></li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <input className="input-transparent" type="text" placeholder="Search" />
                    </div>
                  </div> */}
                </DisplayFlexBT>
                {/* <div className='pt-2  text-white'>
                  <div className='row ms-1 me-1 pt-2 pb-2 text-head bg-head'>
                    <div className="row col-11 align-items-center">
                      <div className='col-1'></div>
                      <div className="col">Pool</div>
                      <div className="col">Liquidity</div>
                      <div className="col d-flex align-items-center">Volume<div className="ms-1" data-tip="" style={{ cursor: "pointer" }}> <AiOutlineQuestionCircle /></div></div>
                      <div className="col">Fees</div>
                      <div className="col">APR</div>
                    </div>
                    <div className="col-1 d-flex justify-content-end">
                      <div style={{ width: 25 }}>
                        <CircularProgressbar value={66} />
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row justify-content-center">
                  <div className="col-12 mt-2">
                    <Accordion_pool />
                  </div>
                </div>
              </Top2Card>
            </Topcard>
          </div>
        </div>
      </div>
      </AppWrapper>
      {/* </BodyWrapper> */}
      {/* <AddLiquidity/> */}
    </>
  )
}
