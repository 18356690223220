import React from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid #173552;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  background: ${({ theme }) => theme.gradient};
  color: ${({ theme }) => theme.colors.white};
  
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.primary1)};
  }
  &:disabled {
    //background: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.gradient : theme.colors.bg3)};
    //color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? 'white' : theme.colors.text3)};
    // background:transparent;
    // color: #5e5e5e;
    cursor: not-allowed;
    // box-shadow: none;
    // border: 1px solid #5e5e5e;
    outline: none;
    
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-radius: 0.75rem !important;
    // --text-color: #3bd0d8;
    // --border-color: #58f3cd;
    // --bg-board-color: rgba(59,208,216,.2);
    // --bg-board-color-2: rgba(59,208,216,0);
    position: relative;
    -webkit-backdrop-filter: blur(calc(var(--blur-size) * (-1 * var(--is-scrolling, 0) + 1)));
    backdrop-filter: blur(calc(var(--blur-size) * (-1 * var(--is-scrolling, 0) + 1)));
    color: #3bd0d8;
    background: linear-gradient(162deg,rgba(59,208,216,.2) 28.7%,rgba(59,208,216,0),rgba(59,208,216,.2));
    isolation: isolate;

    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '0.6')};
  }
`

export const ButtonLight = styled(Base)`
  background: ${({ theme }) => theme.gradient};
  color: ${({ theme }) => theme.colors.buttonText};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.primary5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.03, theme.colors.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.primary5)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.primary5)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.colors.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.colors.bg3};
  color: ${({ theme }) => theme.colors.text2};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.colors.bg2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg2)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.colors.bg2)};
  }
`

export const ButtonSecondary = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary5};
  color: ${({ theme }) => theme.colors.primaryText1};
  font-size: 16px;
  border-radius: 8px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.primary4};
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.colors.primary4};
    background-color: ${({ theme }) => theme.colors.primary4};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary5};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.colors.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.primary1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.primary1)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.primary1};
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonOutlined = styled(Base)`
  //border: 1px solid ${({ theme }) => theme.colors.bg2};
  border: 1px solid #888D9B;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text1};

  &:focus {
    //box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bg4};
    border: 1px solid #8c328f;
  }
  &:hover {
    //box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bg4};
    border: 1px solid #8c328f;
  }
  &:active {
    //box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.bg4};
    border: 1px solid #8c328f;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.advancedBG};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid #edeef2;
  background-color: ${({ theme }) => theme.colors.bg1};
  color: black;

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.colors.green1)};
  color: ${({ theme }) => theme.colors.green1};
  border: 1px solid ${({ theme }) => theme.colors.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.colors.red1};
  border: 1px solid ${({ theme }) => theme.colors.red1};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.colors.red1)};
    background-color: ${({ theme }) => darken(0.05, theme.colors.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.colors.red1)};
    background-color: ${({ theme }) => darken(0.1, theme.colors.red1)};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.red1};
    border: 1px solid ${({ theme }) => theme.colors.red1};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
